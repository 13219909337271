import React, { ReactNode, createContext, useContext, useState, useEffect } from 'react';
import set from 'lodash/set';
import Format from 'types/format.type';
import { MODEL_AD_SETUP } from 'components/bricks/constants';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import useBrick from 'components/bricks/hooks/useBrick';
import BricksComponentStoreHelper from 'components/bricks/helpers/bricks-component-store.helper';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import cloneDeep from 'helpers/cloneDeep';
import { CreativeV2Helpers } from 'components/creatives-v2/helpers/creatives-v2.helpers';
import { Brick } from 'components/bricks/types/brick.type';
import { DynamicValueOption } from 'components/input/DynamicData/types/dynamic-value.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import BrickHelpers, { IAdditionalVars } from 'components/bricks/helpers/brick.helpers';
import { Placement, PlacementsSection } from 'components/bricks/types/placement.type';
import { CreativeV2FormatHelpers } from 'components/creatives-v2/helpers/formats.helpers';
import { DynamicValueHelper } from '../../bricks-multi-input-block-wrapper/dynamic-value.helper';
import { AdSetup, AdSetupItemPlacement, AdSetupPlacements } from '../types/AdSetup.type';

interface ComponentStoreProps {
    activeTab: BricksComponentStore['activeTab'];
}

interface AdSetupContextType {
    adSetup: AdSetup;
    selectedFrame: number;
    creatives?: CreativeV2[];
    loaded: boolean;
    updateAdSetupFull: (newAdSetup: AdSetup) => void;
    updateAdSetupProperty: (property: string, value: unknown, isItem?: boolean) => void;
    updateSelectedFrame: (value: number) => void;
    updateFrameCreatives: (frameCreatives: CreativeV2[]) => void;
    dynamicValueOptions: DynamicValueOption[];
}

interface Props {
    children: ReactNode;
}

const AdSetupContext = createContext<AdSetupContextType>({
    adSetup: {},
    selectedFrame: 0,
    creatives: undefined,
    loaded: false,
    updateAdSetupFull: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateAdSetupProperty: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateSelectedFrame: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    updateFrameCreatives: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
    dynamicValueOptions: []
});
AdSetupContext.displayName = 'AdSetupContext';

const useAdSetupContext = (): AdSetupContextType => {
    return useContext(AdSetupContext);
};

/**
 * Custom hook for ad setup
 */
const AdSetupProvider = ({ children }: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [frameCreatives, setFrameCreatives] = useState<CreativeV2[]>([]);
    const [selectedFrame, setSelectedFrame] = useState<number>(0);
    const [dynamicValueOptions, setDynamicValueOptions] = useState<DynamicValueOption[]>([]);

    const { activeTab } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: {
            activeTab: 'activeTab'
        }
    });

    const { brick } = useBrick();

    useEffect(() => {
        if (!brick) return;
        if (!activeTab) return;

        const additionalVars: IAdditionalVars = {
            brick: BrickHelpers.getBrickById(brick.id),
            ...BrickHelpers.getAdditionalBrickVars(brick)
        };

        DynamicValueHelper.getAllDynamicValueOptions(brick, activeTab, additionalVars).then((options) => {
            setDynamicValueOptions(options);
        });
    }, [brick]);

    useEffect(() => {
        const loadCreatives = async () => {
            setLoaded(false);
            const brickCreatives: CreativeV2[] = cloneDeep(brick?.data?.creatives);
            if (!brickCreatives) {
                setLoaded(true);
                setFrameCreatives([]);
                return;
            }

            const frameCreativeIds = brick?.data?.adSetup?.items?.[selectedFrame]?.creativeIds;
            const filteredCreatives = brickCreatives.filter((creative) => frameCreativeIds?.includes(creative.id));

            await Promise.all(filteredCreatives.map((creative) => CreativeV2Helpers.loadTemplateForCreative(creative)));

            setFrameCreatives(filteredCreatives);
            setLoaded(true);
        };

        loadCreatives();
    }, [selectedFrame, frameCreatives?.length]);

    useEffect(() => {
        // if the creatives are not loaded we cannot calculate the placements
        if (!loaded) return;

        // Frame assigned placements
        const frameAssignedPlacements: AdSetupPlacements | undefined = getAssignedAssetsToPlacements(frameCreatives || []);

        updateAdSetupProperty('placements', frameAssignedPlacements, true);
    }, [frameCreatives?.length, loaded]);

    // We use this function to update the frame creatives manually (e.g. in ad-setup-type handleSelectCreatives)
    const updateFrameCreatives = (frameCreatives: CreativeV2[]): void => {
        setFrameCreatives(frameCreatives);
    };

    if (!brick) return;

    const validateDebounce = (newBrick: Brick) => {
        ValidateHelpers.validate(newBrick);
    };

    const updateAdSetupFull = (newAdSetup: AdSetup): void => {
        const brickCopy: Brick = cloneDeep(brick);
        set(brickCopy, MODEL_AD_SETUP, newAdSetup);
        validateDebounce(brickCopy);
        BricksComponentStoreHelper.setBrickModel(brick.id, MODEL_AD_SETUP, newAdSetup);
    };

    const updateSelectedFrame = (value: number): void => {
        setSelectedFrame(value);
    };

    /**
     * Handles on change of property
     * @param path The path to the property that is being changed
     * @param value The new value for the property
     * @param isItem If the property is an item, it sets the value in the selected frame in the items array. If not, it sets the value in the root
     */
    const updateAdSetupProperty = (path: string, value: unknown, isItem = false): void => {
        const adSetupCopy = cloneDeep(brick.data?.adSetup);
        const finalPath = isItem ? `items.${selectedFrame}.${path}` : path;
        set(adSetupCopy, finalPath, value);
        updateAdSetupFull(adSetupCopy);
    };

    /**
     * Assings assets to placements by their dimensional requirenments
     * @param creatives
     * @returns object of ad setup placements
     */
    const getAssignedAssetsToPlacements = (creatives: CreativeV2[]): AdSetupPlacements => {
        // Placements object for the brick type
        const placementsSectionsData = (BrickHelpers.getBrickData(brick?.subType, 'placements') || []) as PlacementsSection[];

        // Selected placements from meta ad set
        const parentBrick = BrickHelpers.getBrickById(brick?.parentId);
        const selectedPlacements: string[] = parentBrick?.data?.settings?.targeting?.placements || [];

        // Loopsthorugh all placements to assign formats to them
        return placementsSectionsData.reduce((acc: AdSetupPlacements, section: PlacementsSection) => {
            section.children.forEach((placement) => {
                // Check if the current placement is included in parent
                if (selectedPlacements.length && !selectedPlacements.includes(placement.key)) return acc;

                // Gets appropriate format for placement
                const { creative, creativeFormatKey } = getClosestAsset(creatives, placement);

                // Checks there is any creative suitable for that placement
                if (!creative) return acc;

                const placementKey = placement.key;

                const itemPlacement: AdSetupItemPlacement = {
                    formatKey: creativeFormatKey,
                    creativeId: creative.id
                };

                acc = { ...acc, [placementKey]: itemPlacement };
            });

            return acc;
        }, {});
    };

    /**
     * Get format that fits the most for a placement
     * @param creatives
     * @param placement
     * @returns the creativeIndex and the formatIndex in the creative
     */
    const getClosestAsset = (creatives: CreativeV2[], placement: Placement): { creative?: CreativeV2; creativeFormatKey?: string } => {
        let closestDiff: number | undefined;
        let creative: CreativeV2 | undefined;
        let creativeFormatKey: string | undefined;
        const placementAspectRatio = placement.recommendedWidth / placement.recommendedHeight;
        const placementMinWidth = placement.minWidth || 0;
        const placementMinHeight = placement.minHeight || 0;

        // Loops through the creative to get the perfect match for the placement
        for (const enrichedCreative of creatives) {
            const enrichedCreativeType = enrichedCreative.type;
            let creativeFormats: Format[] = [];

            if (enrichedCreativeType === 'customUpload') {
                const { width, height } = enrichedCreative.data;

                const currentDiff = getDifference(width, height, placementAspectRatio, placementMinWidth, placementMinHeight);

                // Checks if that difference is less than the previous one
                // If it is  0 it is the perfect match
                if (typeof currentDiff !== 'undefined' && (!closestDiff || currentDiff < closestDiff)) {
                    closestDiff = currentDiff;
                    creative = enrichedCreative;
                }

                continue;
            }

            if (enrichedCreativeType === 'template') creativeFormats = CreativeV2FormatHelpers.getActiveFormats(enrichedCreative);

            // Loops through the formats to get the perfect format for the placement
            creativeFormats.forEach((format) => {
                const { width, height } = format;

                const currentDiff = getDifference(width, height, placementAspectRatio, placementMinWidth, placementMinHeight);

                // Checks if that difference is less than the previous one
                // If it is  0 it is the perfect match
                if (typeof currentDiff !== 'undefined' && (typeof closestDiff === 'undefined' || currentDiff < closestDiff)) {
                    closestDiff = currentDiff;
                    creative = enrichedCreative;
                    creativeFormatKey = format.key;
                }
            });
        }

        return { creative, creativeFormatKey };
    };

    /**
     * Get dimensional difference between creative and placement
     * @param assetWidth
     * @param assetHeight
     * @param placementAspectRatio
     * @param placementMinWidth
     * @param placementMinHeight
     * @returns difference
     */
    const getDifference = (assetWidth = 0, assetHeight = 0, placementAspectRatio: number, placementMinWidth: number, placementMinHeight: number) => {
        // Asset ratio of the asset
        const assetAspectRatio = assetWidth / assetHeight;

        // Checks the format if the min width and the height for a placemnet fits
        if (assetWidth >= placementMinWidth && assetHeight >= placementMinHeight) {
            // Formula to calculate the difference
            const currentDiff = Math.abs(placementAspectRatio - assetAspectRatio);
            return currentDiff;
        }
    };

    const contextValue = {
        adSetup: brick.data?.adSetup || {},
        selectedFrame,
        creatives: frameCreatives,
        loaded,
        updateAdSetupFull,
        updateAdSetupProperty,
        updateSelectedFrame,
        updateFrameCreatives,
        dynamicValueOptions
    };

    return <AdSetupContext.Provider value={contextValue}>{children}</AdSetupContext.Provider>;
};

export default AdSetupProvider;
export { useAdSetupContext, AdSetupContext, AdSetupProvider };
