import React from 'react';
import useBrick from 'components/bricks/hooks/useBrick';
import { BrickType } from 'components/bricks/types/brick.type';
import { useAdSetupContext } from '../context/ad-setup-context';
import './../styles/main.scss';

interface Props {
    children: React.ReactNode;
}

const AdSetupInputWrapper = ({ children }: Props) => {
    const { dynamicValueOptions } = useAdSetupContext();

    const { brick } = useBrick();

    if (!brick) return null;

    // if the brick is not a master or master child brick, we don't need add dynamic value options to it
    if (![BrickType.MASTER, BrickType.MASTER_CHILD].includes(brick.type)) {
        return children;
    }

    // add dynamicValueActive to the children component

    return React.cloneElement(children as React.ReactElement, {
        dynamicValueActive: true,
        dynamicValueOptions: dynamicValueOptions
    });
};

export default AdSetupInputWrapper;
