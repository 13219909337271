import React, { useMemo } from 'react';
import './../styles/ad-placements-item.scss';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import useBrick from 'components/bricks/hooks/useBrick';
import { BricksComponentStore } from 'components/bricks/types/bricksComponentStore.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import useValidations from 'components/bricks/hooks/useValidations';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import AssetHelper from 'helpers/asset.helper';
import { useBricksNavigation } from 'components/bricks/hooks/useBricksNavigation';
import { PlacementItemInterface } from './ad-placements';
import { useAdSetupContext } from '../context/ad-setup-context';

interface PlacementItemProps {
    item: PlacementItemInterface;
}

interface ComponentStoreProps {
    activeTab: BricksComponentStore['activeTab'];
}

const PlacementItem: React.FC<PlacementItemProps> = ({ item }) => {
    const { activeItemId } = useBricksNavigation();
    const { activeTab } = useComponentStore<ComponentStoreProps>('Bricks', {
        fields: { activeTab: 'activeTab' }
    });

    const { selectedFrame } = useAdSetupContext();
    const { brick } = useBrick();
    const { validations } = useValidations(
        activeItemId ? [activeItemId] : undefined,
        activeTab?.key,
        brick ? `${brick.subType}-ad_setup-placements-${item.key}-${selectedFrame}` : undefined
    );

    /**
     * Gets description for the placement item
     * @returns description
     */
    const description: string = useMemo(() => {
        if (item.creativeTitle)
            if (item.formatName) return item.creativeTitle + ' - ' + item.formatName;
            else return item.creativeTitle;
        return item.recommendedWidth + ' : ' + item.recommendedHeight;
    }, [item]);

    if (!activeItemId || !brick) return;

    return (
        <div className="placement-item">
            <Tooltip title={Translation.get('adSetup.placements.item.noAssignedCreatives', 'bricks')} disableHoverListener={!validations?.length}>
                <div
                    className={classNames('placement-item__image-container', {
                        'placement-item__image-container--warning': validations?.length
                    })}>
                    {item.creativeUrl && !AssetHelper.urlIsVideo(item.creativeUrl) && (
                        <img
                            className="placement-item__image-container__image"
                            src={item.creativeUrl}
                            height={item.recommendedHeight}
                            width={item.recommendedWidth}
                            alt="Creative"
                        />
                    )}
                    {!item.creativeUrl && !validations?.length && <Icon>bolt</Icon>}
                    {item.creativeUrl && AssetHelper.urlIsVideo(item.creativeUrl) && <Icon>bolt</Icon>}
                    {!item.creativeUrl && !!validations?.length && <Icon>warning_amber</Icon>}
                </div>
            </Tooltip>
            <div className="placement-item__text">
                <span className="placement-item__text__title">{item.label}</span>
                <Tooltip title={description}>
                    <span className="placement-item__text__description">{description}</span>
                </Tooltip>
            </div>
        </div>
    );
};

export default PlacementItem;
